import { getCookie } from '../../utils/cookie';
import axiosClient from '../axiosClient';
export const createBotStore = async (data) => {
    const token = getCookie('access_token');

    try {
        const res = await axiosClient.post(`/clone-bot`, data, { headers: { Authorization: `Bearer ${token}` } });
        if (res.status === 200 || res.status === 201) {
            return {
                success: true,
                data: res.data,
            };
        } else {
            return {
                success: false,
                data: res,
            };
        }
    } catch (error) {
        return {
            success: false,
            data: error,
        };
    }
};

export const getPayment = async (storeId) => {
    try {
        const res = await axiosClient.get(`/vnpay-payment-store-bot/${storeId}`);
        if (res.status === 200 || res.status === 201) {
            return {
                success: true,
                data: res.data,
            };
        } else {
            return {
                success: false,
                data: res,
            };
        }
    } catch (error) {
        return {
            success: false,
            data: error,
        };
    }
};

export const getCategoryStore = async (limit = 100) => {
    try {
        const res = await axiosClient.get(`/category-bot-stores/?limit=${limit}`);
        if (res.status === 200 || res.status === 201) {
            return {
                success: true,
                data: res.data.results,
            };
        } else {
            return {
                success: false,
                error: res,
            };
        }
    } catch (error) {
        return {
            success: false,
            error: error,
        };
    }
};
