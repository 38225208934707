import axios from 'axios';
import axiosClient from '../axiosClient';
import { filterDuplicateQuestions } from '../../utils/sorting';
import { data } from 'autoprefixer';
const baseUrl = process.env.REACT_APP_API_V3_URL;
// lấy key jwt chat với bot
export const getKeychat = async (bot_id) => {
    try {
        const res = await axiosClient.get('passport', {
            headers: {
                'X-Bot-Code': `${bot_id}`,
            },
        });
        if (res.status <= 205) {
            const parsedBotIds = res.data.access_token;
            return parsedBotIds;
        } else {
            return res;
        }
    } catch (error) {
        return error;
    }
};

// lấy danh sách hội thoai
export const getListConversationToChat = async (tokenChat, limit) => {
    const jwt = tokenChat?.includes('"') ? tokenChat.slice(1, -1) : tokenChat;

    try {
        const response = await axios.get(`${baseUrl}/api/list-conversation?limit=${limit}`, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        });

        if (response.status <= 205) {
            const convertedData = response.data;
            return {
                success: true,
                data: convertedData || [],
            };
        }
        return {
            success: false,
            data: [],
        };
    } catch (error) {
        return {
            success: false,
            data: [],
        };
    }
};
export const getListConversationToChatGpts = async (idChatAssistant, limit) => {
    try {
        const response = await axios.get(`${baseUrl}/api/assistant-thread?assistant=${idChatAssistant}`);

        if (response.status <= 205) {
            const convertedData = response.data;
            return {
                success: true,
                data: convertedData || [],
            };
        }
        return {
            success: false,
            data: [],
        };

    } catch (error) {
        return {
            success: false,
            data: [],
        };
    }
};

// xoá 1 hội thoại
export const handleDeleteConversation = async (conversationId) => {
    const url = `conversations/delete_instance?conversation_id=${conversationId}`;
    try {
        const res = await axiosClient.delete(url);
        if (res.status >= 200 && res.status <= 209) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return error;
    }
};

// lấy voice đi với câu trả lời
export const getVoiceAnswer = async (conversation_id) => {
    if (!conversation_id) {
        return {
            success: false,
            message: 'Conversation ID is required',
            data: null,
        };
    }

    try {
        const res = await axiosClient.get(`tts-answer/${conversation_id}`, {
            responseType: 'blob', // Chỉ định loại dữ liệu phản hồi là 'blob'
        });

        if (res.status === 200 || res.status === 201) {
            return {
                success: true,
                message: 'Voice answer retrieved successfully',
                data: res.data,
            };
        } else {
            return {
                success: false,
                message: 'Failed to retrieve voice answer',
                data: null,
            };
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            return {
                success: false,
                message: 'Error retrieving voice answer',
                data: null,
                error: {
                    status: error.response.status,
                    message: error.response.data.message || 'Unknown error',
                },
            };
        } else if (error.request) {
            // The request was made but no response was received
            return {
                success: false,
                message: 'No response received from the server',
                data: null,
                error: {
                    message: 'No response received',
                },
            };
        } else {
            // Something happened in setting up the request that triggered an Error
            return {
                success: false,
                message: 'Error setting up the request',
                data: null,
                error: {
                    message: error.message,
                },
            };
        }
    }
};

// lấy thông tin của bot
export const getInfobot = async (botId) => {
    try {
        const response = await axiosClient.get(`/embed/bot/${botId}`);
        if (response.status < 205) {
            return response.data;
        }
        return response;
    } catch (error) {
        return error;
    }
};
export const getInfobotGpts = async (botId) => {
    try {
        const response = await axiosClient.get(`/embed/assistant/${botId}`);
        if (response.status < 205) {
            return { ...response.data, ...response.data.profile_assistants };
        }
        return response;
    } catch (error) {
        return error;
    }
};

// lấy danh sách tập dữ liệu chọn chat
export const getPublicDataset = async (botId) => {
    try {
        const response = await axiosClient.get(`/public-dataset/?bot_id=${botId}`);
        if (response.status < 205) {
            return response.data;
        }
        return response;
    } catch (error) {
        return error;
    }
};

// lấy nội dung của hội thoại bằng conversation id
export const getHistoryChat = async (conversatioId) => {
    try {
        const response = await axiosClient.get(`/conversations/history?conversation_id=${conversatioId}`);
        if (response.status < 205) {
            return response.data;
        }
        return response;
    } catch (error) {
        return error;
    }
};
export const getHistoryChatGpts = async (conversatioId) => {
    try {
        const response = await axiosClient.get(`assistant-details-thread?thread=${conversatioId}`);
        if (response.status < 205) {
            return {
                success: true,
                data: response.data,
            };
        }
        return {
            success: false,
            response,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
};

// lấy câu chào đầu người dùng
export const getUserGreeting = async (botId) => {
    try {
        const res = await axiosClient.get(`greeting-message?bot_id=${botId}`);
        if (res.status === 200 || res.status === 201) {
            if (res.status < 205) {
                return res.data.greeting_message;
            }
            return false;
        }
    } catch (error) {
        return false;
    }
};

// lấy câu hỏi flow up
// flowupService.js

export const getFlowupByConverstaionId = async (id) => {
    try {
        const res = await axiosClient.get(`find-followup/${id}`);
        if (res.status === 200 || res.status === 201) {
            const newdata = res.data.map((i) => ({ question: i }));
            return newdata;
        } else {
            return [];
        }
    } catch (error) {
        // Xử lý lỗi nếu cần
        console.error('Lỗi khi lấy dữ liệu câu hỏi tiếp theo:', error);
        return [];
    }
};

// lấy câu hỏi gọi ý chào đầu

export const getWellcomeQuestions = async (botId) => {
    try {
        const res = await axiosClient.get(`starters-questions/?bot_id=${botId}`);
        if (res.status === 200 || res.status === 201) {
            const welcomQuesiton = filterDuplicateQuestions(res.data);
            return welcomQuesiton;
        } else {
            return [];
        }
    } catch (error) {
        return [];
    }
};

// lấy danh sách chọn dataset để trả lời
export const getListDataset = async (botId) => {
    try {
        const res = await axiosClient.get(`public-dataset/?bot_id=${botId}`);
        if (res.status < 205) {
            return res.data.results;
        } else {
            return {};
        }
    } catch (error) {
        return [];
    }
};

// lấy thông tin tài khoản đã mua gói
export const getUserInfoActivePackage = async (jwt) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_V3_URL}/api/end-user-info`, {
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        });
        if (res.status <= 205) {
            return res.data;
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
};

/// lấy danh sách package

export const getListPackageBot = async (botId) => {
    try {
        const res = await axiosClient.get(`/list-package-bot/${botId}`);

        if (res.status === 200 || res.status === 201) {
            return res.data;
        } else {
            return [];
        }
    } catch (error) {
        return [];
    }
};
// lấy câu autocomplate
export const getSuggestion = async (botId, query) => {
    try {
        const res = await axiosClient.get(`/question/autocomplete?bot_id=${botId}&keyword=${query}`);
        if (res.status === 200 || res.status === 201) {
            const arr = res.data.data.map((i) => i.split(' /n '));
            const obj = arr.map((data) => data.reduce((a, v) => ({ ...a, id: a.question, question: v }), {}));
            return obj.splice(0, 5);
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
};
