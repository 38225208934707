import { atom } from 'recoil';

export const listStartDisplay = atom({
    key: 'listStartDisplay',
    default: { loading: false, results: [] },
});
export const listFunctionMindmaidState = atom({
    key: 'listFunctionMindmaidState',
    default: { loading: false, data: null },
});

export const listEmailListState = atom({
    key: 'listEmailListState',
    default: { loading: false, results: [] },
});

export const popUpEidtInfoAndCloder = atom({
    key: 'popUpEidtInfoAndCloder',
    default: { open: false, data: null, type: '' },
});

export const listBotPackage = atom({
    key: 'listBotPackage',
    default: { loading: false, results: [] },
});

export const openPopupShare = atom({
    key: 'openPopupShare',
    default: { open: false, title: '', layout: '', activeKey: '' },
});

export const openPopupCommon = atom({
    key: 'openPopupCommon',
    default: { open: false, title: '', layout: '', activeKey: '' },
});
export const packageState = atom({
    key: 'packageState',
    default: null,
});

export const connectionStatusShare = atom({
    key: 'connectionStatusShare',
    default: {},
});

export const listBankState = atom({
    key: 'listBankState',
    default: [],
});

export const openModalProviderAppSetting = atom({
    key: 'openModalProviderAppSetting',
    default: { open: false, layout: null, type: '', title: '', description: '', isReset: false },
});

export const dataUpdatelProviderAppSetting = atom({
    key: 'dataUpdatelProviderAppSetting',
    default: null,
});

export const listbot = atom({
    key: 'listbot',
    default: [],
});

export const popupModalDataset = atom({
    key: 'popupModalDataset',
    default: { title: '', key: '', layout: '', open: false, data: null },
});
export const workflowSate = atom({
    key: 'workflowSate',
    default: { total: 0, data: [] },
});

export const packagesState = atom({
    key: 'packagesState',
    default: [],
});

export const inforBotState = atom({
    key: 'inforBotState',
    default: {},
});

export const InfoUserPackageBotState = atom({
    key: 'InfoUserPackageBotState',
    default: {},
});

export const isGetHistorychat = atom({
    key: 'isGetHistorychat',
    default: true,
});

export const idDatasetState = atom({
    key: 'idDatasetState',
    default: '',
});

export const settingdatasetInfoSate = atom({
    key: 'settingdatasetInfoSate',
    default: null,
});

export const settingOptionAI = atom({
    key: 'settingOptionAI',
    default: { title: '', open: false },
});

export const createBotState = atom({
    key: 'createBotState',
    default: { type: '', isOpen: false },
});

export const promptDebugState = atom({
    key: 'promptDebugState',
    default: { prompt: '', isOpen: false, loading: false, question: '' },
});

export const listBotSetting = atom({
    key: 'listBotSetting',
    default: [],
});

/// config state chat preview
export const chatConfiguration = atom({
    key: 'chatconfiguration',
    default: {
        botId: null,
        loading: false,
        keyChat: null,
        conversationId: null,
        isNewchat: false,
        disabledChat: false,
        datasetId: null,
        livechat:false,
        isForm: {
            identification: false,
            personalization: false,
            loginEmail: false,
        },
        users: {
            isLogin: false,
            name: '',
            email: '',
            phone: '',
            userId: null,
            isActiveEmail: false,
            isActivePackage: false,
        },
    },
});

export const categoryStoreState = atom({
    key: 'categoryStoreState',
    default: [],
});
// list sidebar conversation
export const chatConverstation = atom({
    key: 'chatConverstation',
    default: [],
});

// list sidebar conversation
export const infobotPreviewState = atom({
    key: 'infobotPreviewState',
    default: {},
});

// list histoyry conversation
export const listChatHistoryState = atom({
    key: 'listChatHistory',
    default: [],
});

// files upload
export const dataFilesState = atom({
    key: 'dataFilesState',
    default: [],
});

// files upload
export const dataFlowupState = atom({
    key: 'dataFlowupState',
    default: [],
});

export const botListState = atom({
    key: 'botListState',
    default: [],
});

export const storeBotState = atom({
    key: 'storeBotState',
    default: {
        open: false,
        data: null,
    },
});
