import axios from 'axios';
import { decodeToken } from 'react-jwt';
import { getCookie, isTokenExpired, setCookie } from '../utils/cookie';

const axiosClient = axios.create({
    // sever staging
    // baseURL: `${process.env.REACT_APP_STAGING}/api/`,

    // production
    baseURL: `${process.env.REACT_APP_API_V3_URL}/api/`,

    headers: {
        'Content-Type': 'application/json',
    },
});

axiosClient.interceptors.request.use(
    (config) => {
        const token = getCookie('access_token');
        const isTokenValid = decodeToken(token);
        if (token && isTokenValid) {
            config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
            // config.headers['x-access-token'] = token; // for Node.js Express back-end
        }
        return config;
    },
    (error) => {
        return error;
    },
);

axiosClient.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        const refreshToken = getCookie('refresh_token');
        const isTokenValid = decodeToken(refreshToken);
        const isTokenExpire = isTokenExpired(isTokenValid?.exp);
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry && isTokenValid) {
            originalConfig._retry = true;

            try {
                const rs = await axiosClient.post('auth/token/refresh/', {
                    refresh: refreshToken,
                });
                const accessToken = rs.data?.access;
                if (accessToken) setCookie('access_token', accessToken);
                else if (isTokenExpire) {
                    setCookie('access_token', null);
                    setCookie('access_token', null);
                }

                return axiosClient(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }

        return err.response;
    },
);

export default axiosClient;
